html { min-height: 100%; font-family: 'Quicksand', sans-serif; }
body { color: #bfc5e0; height: 100%; font-size: 0.875rem; background: #060818; overflow-x: hidden; overflow-y: auto; letter-spacing: 0.0312rem; font-family: 'Quicksand', sans-serif; }
h1, h2, h3, h4, h5, h6 { color: #ced0e1; }
:focus { outline: none; }
::selection { color: #fff; background: #434d5b; }
p { margin-top: 0; margin-bottom: 0.625rem; color: #515365 }
hr { margin-top: 20px; margin-bottom: 20px; border-top: 1px solid #191e3a; }
strong { font-weight: 600; }

a { color: #e0e6ed; outline: none; text-decoration: none; }
a:hover { color: #bfc9d4; text-decoration: none }
a:focus { outline: none; text-decoration: none }

.p-component { font-family: 'Quicksand', sans-serif; }


p-button { margin-right: .25rem; margin-left: .25rem;
  &:first-child { margin-left: 0; }
  &:last-child { margin-right: 0; }
}

.flex-between { display: flex; justify-content: space-between; align-items: center; }

.modal-loading { display: flex; justify-content: center; padding: 100px 0;
  > i { font-size: 2rem; }
}

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item-group { color: #b9b9b9; font-weight: normal; font-size: .8rem; padding-left: 1.5rem; padding-bottom: 0px;
      &:not(:first-of-type) { border-top: solid 1px #3f4b5b; }
    }
  }
}
